<template>
  <div class="md-date-picker">
    <v-menu offset-y min-width="auto" :disabled="disabled">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :rules="rules"
          v-model="result"
          append-icon="mdi-calendar"
          outlined
          readonly
          dense
          :clearable="!required"
          :disabled="disabled"
          v-bind="attrs"
          v-on="on"
          @click:append="on.click"
        ></v-text-field>
      </template>
      <v-date-picker v-model="result" no-title scrollable :allowed-dates="allowDates" />
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: [Object, String],
    required: Boolean,
    allowDate: String,
    disabled: Boolean
  },
  model: {
    prop: "value",
    event: "change"
  },
  computed: {
    result: {
      get() {
        var val = this.value;
        if (val) {
          return this.$moment(val).format("YYYY-MM-DD");
        } else {
          return "";
        }
      },
      set(val) {
        val = val ? this.$moment(val).format() : "";
        this.$emit("change", val);
      }
    },
    rules() {
      var r = [];
      if (this.required) {
        r.push((v) => {
          return !!v || "Required";
        });
      }
      if (this.allowDate) {
        r.push((v) => {
          return !v || this.allowDates(v) || "Invalid date";
        });
      }
      return r;
    }
  },
  methods: {
    allowDates(val) {
      if (this.allowDate) {
        return new Date(val).getTime() > new Date(this.allowDate).getTime();
      } else {
        return true;
      }
    }
  }
};
</script>

<style></style>
