import axios from "axios";
import store from "@/store";
import router from "@/router";

import { mutations, states } from "@/constants";

const _axios = axios.create({
  withCredentials: true,
  baseURL: "/api",
  xsrfCookieName: "cpo-x-xsrf-token",
  timeout: 300000
});
_axios.interceptors.request.use(
  (config) => {
    store.commit(mutations.setLoading, true);
    return config;
  },
  (error) => {
    store.commit(mutations.setLoading, false);
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    store.commit(mutations.setLoading, false);
    return response.data;
  },
  (error) => {
    store.commit(mutations.setLoading, false);

    if (axios.isCancel(error)) {
      return Promise.reject({ message: error.message });
    }
    console.log(error.response);
    if (error.response.status == 401) {
      router.push(`/login?return=${store.state[states.returnPath]}`);
      return Promise.reject();
    } else if (error.response.data == "csrf-failed") {
      window.location.reload();
    } else {
      store.commit(mutations.setError, error.response.data);
      router.push(`/error/${error.response.status || 400}`);
      return Promise.resolve();
      // return Promise.reject({ message: error?.response?.message || "An error occured." });
    }
  }
);
export default _axios;
