<template>
  <v-dialog v-model="show" fullscreen transition persistent>
    <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5)">
      <v-layout justify-center align-center>
        <v-progress-circular indeterminate color="orange"></v-progress-circular>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { states } from "@/constants";
import { mapState } from "vuex";
export default {
  data() {
    return {
      load: false
    };
  },
  computed: {
    show: {
      get() {
        return this.loading_toggle && this.loading;
      },
      set() {}
    },
    ...mapState([states.loading, states.loading_toggle])
  }
};
</script>
