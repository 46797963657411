<template>
  <div class="cpo-rich-text-editor">
    <wang-editor-toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" mode="default" />
    <wang-editor
      style="height: 500px; overflow-y: hidden"
      v-model="html"
      :defaultConfig="editorConfig"
      mode="default"
      @onCreated="onCreated"
      @onChange="onChange"
    />
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import "@wangeditor/editor/dist/css/style.css";

export default {
  props: {
    model: String
  },
  model: {
    prop: "model",
    event: "change"
  },
  components: { wangEditor: Editor, wangEditorToolbar: Toolbar },
  data() {
    return {
      editor: null,
      html: "",
      toolbarConfig: { toolbarKeys: ["bold", "underline", "italic", "bulletedList", "numberedList", "|", "uploadImage", "insertVideo", "|", "insertLink"] },
      editorConfig: { placeholder: "请输入内容...", MENU_CONF: {} }
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor);
      editor.setHtml(this.model);
    },
    onChange(editor) {
      this.$emit("change", editor.getHtml());
    }
  },
  created() {
    this.html = this.model;
    this.editorConfig.MENU_CONF["uploadImage"] = {
      customUpload: (file, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var data = { data: reader.result.split("base64,")[1], mimeType: file.type, fileName: file.name };
          this.$axios.post("/admin/uploadFile", data).then((data) => {
            var url = `/api/news/GetResource/${data.id}`;
            callback(url);
          });
        };
      }
    };
  },
  beforeDestroy() {
    if (this.editor == null) return;
    this.editor.destroy();
  }
};
</script>

<style></style>
