export const states = {
  loading: "loading",
  loading_toggle: true,
  returnPath: "",
  error: null
};
export const mutations = {
  setLoading: "SET_LOADING",
  toggleLoading: "TOGGLE_LOADING",
  setError: "SET_ERROR",
  setReturnPath: "SET_RETURN_PATH"
};
