import Vue from "vue";
import Vuex from "vuex";
import { states, mutations } from "@/constants";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    [states.loading]: false
  },
  mutations: {
    [mutations.setLoading](state, val) {
      state[states.loading] = !!val;
    },
    [mutations.setError](state, val) {
      state[states.error] = val;
    },
    [mutations.setReturnPath](state, val) {
      state[states.returnPath] = val;
    },
    [mutations.toggleLoading](state, val) {
      state[states.loading_toggle] = val;
    }
  }
});
