<template>
  <div class="elevation-1 white mb-10">
    <v-form ref="form" class="pa-3" v-if="ready">
      <v-text-field v-model="item.title" label="Title" :rules="[required]" />
      <v-text-field v-model="item.abstract" label="Introduction" :rules="[required]" />
      <v-file-input v-model="item.file" label="Image" @change="onSelectImage" />
      <v-text-field :value="item.image" readonly :rules="[required]" />
      <v-select v-model="item.category" :items="['News', 'Review', 'Competation']" label="Category" :rules="[required]" />
      <v-select v-model="item.location" :items="['UK', 'World']" label="Location" :rules="[required]" />
      <md-date-picker v-model="item.date" required />
      <md-rich-text-editor v-model="item.content" />
    </v-form>
    <div class="d-flex">
      <v-btn depressed color="primary" @click="submit" :loading="loading">Save</v-btn>
      <v-btn text to="/news/admin/index" :loading="loading">Back</v-btn>
    </div>
  </div>
</template>

<script>
import MdRichTextEditor from "@/components/MdRichTextEditor.vue";
import MdDatePicker from "@/components/MdDatePicker.vue";
import { states } from "@/constants";
import { mapState } from "vuex";

export default {
  components: { MdRichTextEditor, MdDatePicker },
  data() {
    return {
      ready: false,
      item: {
        content: "",
        image: ""
      }
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    ...mapState([states.loading])
  },
  methods: {
    required(v) {
      return !!v || "Required";
    },
    onSelectImage(e) {
      this.item.image = e?.name;
    },
    guid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    uploadFile(file) {
      if (file) {
        return new Promise((r, e) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            var req = { data: reader.result.split("base64,")[1], mimeType: file.type, fileName: file.name };
            this.$axios
              .post("/admin/uploadFile", req)
              .then((data) => {
                this.item.image = `/api/news/GetResource/${data.id}`;
                r();
              })
              .catch(e);
          };
        });
      } else {
        return Promise.resolve();
      }
    },
    submit() {
      if (!this.$refs.form.validate()) return;
      this.uploadFile(this.item.file).then(() => {
        delete this.item.file;
        return this.$axios.post("/news/saveItem", this.item).then(() => {
          this.$router.push("/news/admin/index");
        });
      });
    }
  },
  created() {
    if (this.id) {
      this.$axios.post("/news/getItem", { id: this.id }).then((data) => {
        this.item = data;
        this.ready = true;
      });
    } else {
      this.item.id = this.guid();
      this.ready = true;
    }
  }
};
</script>

<style></style>
