import Vue from "vue";
import Router from "vue-router";
import axios from "@/plugins/axios";
import store from "@/store";
import { mutations } from "@/constants";

Vue.use(Router);

var basePath = process.env.BASE_URL || "";

const router = new Router({
  mode: "history",
  base: basePath,
  routes: [
    {
      path: "/",
      name: "default",
      component: require(`@/views/default.vue`).default
    },
    {
      path: "/login",
      name: "login",
      component: require(`@/views/login.vue`).default
    },
    // {
    //   path: "/register",
    //   name: "register",
    //   component: require(`@/views/register.vue`).default
    // },
    {
      path: "/news/:id",
      name: "news-edit",
      component: require(`@/views/news/view.vue`).default
    },
    {
      path: "/news/admin/index",
      name: "admin-news-index",
      component: require(`@/views/news/admin/index.vue`).default,
      meta: {
        auth: true
      }
    },
    {
      path: "/news/admin/edit/:id?",
      name: "admin-news-edit",
      component: require(`@/views/news/admin/edit.vue`).default,
      meta: {
        auth: true
      }
    },
    {
      path: "/error/:errorCode?",
      name: "error",
      component: require(`@/views/error.vue`).default
    },
    {
      path: "*",
      name: "fallback",
      component: require(`@/views/404.vue`).default
    }
  ]
});
router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    store.commit(mutations.setReturnPath, to.path);
    axios
      .post("/auth/check", {})
      .then(() => {
        store.commit(mutations.setReturnPath, "");
        next();
      })
      .catch(() => {});
  } else {
    store.commit(mutations.setReturnPath, "");
    next();
  }
});
router.afterEach(() => {
  window.scrollTo(0, 0);
});
export default router;
