<template>
  <v-app>
    <md-header class="container" />
    <loading />
    <router-view class="container" />
  </v-app>
</template>

<script>
import MdHeader from "./components/MdHeader.vue";
import loading from "./components/loading.vue";
export default {
  components: { MdHeader, loading }
};
</script>

<style></style>
