<template>
  <div class="container layout flex-column justify-center" style="height: 100%">
    <v-form @submit.prevent.stop="submit" class="d-flex justify-center pb-8">
      <div class="flex sm12 md6 xl4 elevation-1 white pa-8">
        <h1 class="mb-4">Login</h1>
        <v-text-field v-model="model.userName" label="UserName" :rules="[required]" />
        <v-text-field v-model="model.password" type="password" label="Password" :rules="[required]" />
        <div class="layout mt-4">
          <v-spacer />
          <v-btn type="submit" depressed color="primary">Submit</v-btn>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      model: {}
    };
  },
  methods: {
    submit() {
      this.$axios.post("/auth/login", this.model).then(() => {
        window.location.href = this.$route.query.return || "/";
      });
    },
    required(v) {
      return !!(v && v.length) || "Required";
    }
  }
};
</script>

<style lang="scss"></style>
