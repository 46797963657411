<template>
  <div class="admin-module">
    <div class="layout align-center">
      <h1>News</h1>
      <v-spacer />
      <v-btn color="primary" depressed to="/news/admin/edit"> New Item </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      :options.sync="settings"
      :server-items-length="total"
      :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
    >
      <template v-slot:item.action="{ item }">
        <v-btn text color="primary" :to="`/news/admin/edit/${item.id}`">Edit</v-btn>
        <v-btn text color="primary" @click="deleteItem(item.id)">Delete</v-btn>
      </template>
      <template v-slot:item.date="{ item }">
        {{ $moment(item.date).format("DD MMM YYYY") }}
      </template>
      <template v-slot:item.modifiedDate="{ item }">
        {{ $moment(item.modifiedDate).format("DD MMM YYYY") }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Title", value: "title" },
        { text: "Type", value: "category" },
        { text: "Location", value: "location" },
        { text: "Release Date", value: "date" },
        { text: "Modified Date", value: "modifiedDate" },
        { text: "", value: "action" }
      ],
      items: [],
      total: 1,
      settings: {
        page: 1,
        itemsPerPage: 10
      }
    };
  },
  methods: {
    load() {
      this.$axios
        .post("/news/count", { filter: [], sort: [] })
        .then((data) => {
          this.total = data.total;
          return this.$axios.post("/news/list", {
            filter: [],
            sort: [{ field: "modifiedDate", asc: false }],
            page: this.settings.page,
            pageSize: this.settings.itemsPerPage
          });
        })
        .then((data) => {
          this.items = data;
        });
    },
    deleteItem(id) {
      this.$axios.delete(`/news/deleteItem/${id}`).then(this.load);
    }
  },
  watch: {
    settings: {
      handler: "load",
      deep: true
    }
  }
};
</script>

<style></style>
