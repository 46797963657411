<template>
  <div class="md-header">
    <a href="/"><img src="/img/logo-01@2x.png" class="logo" /></a>
    <v-spacer />
    <v-btn icon class="mr-3" color="primary" large><v-icon large>mdi-magnify</v-icon></v-btn>
    <!-- <v-btn icon class="mr-3" color="primary" large><v-icon large>mdi-menu</v-icon></v-btn> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.md-header {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  img.logo {
    width: 240px;
  }
}
@media (min-width: 960px) {
  .md-header {
    img.logo {
      width: 320px;
    }
  }
}
</style>
