<template>
  <div></div>
</template>

<script>
export default {
  created() {
    this.$router.replace("/error/404");
  }
};
</script>

<style></style>
