<template>
  <div>
    <pre>{{ error }}</pre>
  </div>
</template>

<script>
import { states } from "@/constants";
export default {
  computed: {
    error() {
      return this.$store.state[states.error];
    }
  }
};
</script>

<style></style>
