import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import "./assets/style.scss";
import axios from "@/plugins/axios";
import moment from "moment";

Vue.config.productionTip = false;

Object.defineProperties(Vue.prototype, {
  $axios: {
    get() {
      return axios;
    }
  },
  $moment: {
    get() {
      return moment;
    }
  }
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
